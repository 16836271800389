@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f8fafc;
}

/* Create loading shimmer effect to fit any element  */
.shimmer {
  background: linear-gradient(to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 20%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0) 100%);
  background-size: 80rem 14rem;
  animation: shimmer 1s linear infinite forwards;
}

@keyframes shimmer {
  100% {
    background-position: 80rem 0;
  }
}

/* Create loading skeleton effect to fit any element  */

.skeleton {
  background-color: #ddd;
  background-image: linear-gradient(90deg,
      #ddd,
      #eaeaea,
      #ddd);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  animation: skeleton 1s ease-in-out infinite;
}


.note-box {
  overflow-y: scroll;
  height: 40vh;
}

@keyframes skeleton {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}